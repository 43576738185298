import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql } from 'gatsby';

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";
import Section from "../../components/section";
import AnyQuestions from "../../components/any-questions";
import Testimonial from "../../components/testimonial";

const Testimonials = (props) => {
    return <Layout businessUp test>
        <SEO 
            title={props.intl.formatMessage({id: "Opinions and reviews of BusinessUp by Adventurees"})} 
            description={props.intl.formatMessage({id: "Find out what our customers think about Adventurees and BusinessUp"})}
            location={props.location}
        />

        <Banner 
            className="banner"
            colSize='col-md-8 align-self-center'
            filename="testimonials.jpg"
            title={props.intl.formatMessage({id: "Testimonials"})}
            subtitle={props.intl.formatMessage({id: "Our clients share their opinions about the advantages of working with us"})}
        />

        <Section title={props.intl.formatMessage({id: "Testimonials"})}>
            <div className="row testimonials justify-content-center">
                    {props.data.allTestimonialsJson.edges.map((element, index) => {
                        return <Testimonial key={index} testimonial={element.node} />
                    })}
            </div>
        </Section>
        
        <AnyQuestions />
    </Layout>
}

export default injectIntl(Testimonials);


export const setup = graphql`
    query DevTestimonialsQuery
    {
        allTestimonialsJson
        {
            edges
            {
                node
                {
                    author {name imagePath job company}
                    content
                }
            }
        }
    }
`; 