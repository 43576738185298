import React, { useState } from "react";
import { injectIntl } from "gatsby-plugin-intl";

import "../styles/testimonials.less";

const Testimonial = (props) => {

    const [clipClass, toggleClipClass] = useState(true)

    let clip = false;
    if (props.intl.formatMessage({id: props.testimonial.content}).length > 400)
    {
        clip = true;
    }

    return <div className="col-lg-4 col-sm-12 testimonial">
        <div className={clipClass? "testimonial-box fixed-height" : "testimonial-box"}>
            <div className="testimonial-author">
                <div className="author-image-container">
                    <img src={props.testimonial.author.imagePath} alt={props.testimonial.author.name} />
                </div>
                <p className="author-name">{props.testimonial.author.name}</p>
                <p className="author-job-position">{props.intl.formatMessage({id: props.testimonial.author.job})} {props.intl.formatMessage({id: 'at'})} {props.testimonial.author.company}</p>
            </div>
            <hr />

            {!clip && <div className="testimonial-content">
                <blockquote className="curly-quotes">
                    {props.intl.formatMessage({id: props.testimonial.content})}
                </blockquote>
            </div>}

            {clip && <>
                <div className={clipClass? "testimonial-content clip" : "testimonial-content"}>
                    <blockquote className="curly-quotes">
                        {props.intl.formatMessage({id: props.testimonial.content})}
                    </blockquote>
                </div>
                <button className="see-more-trigger" onClick={() => toggleClipClass(!clipClass)}>{clipClass? props.intl.formatMessage({id: "See more"}) : props.intl.formatMessage({id: "See less"})}</button>
            </>}
        </div>
    </div>
}

export default injectIntl(Testimonial);